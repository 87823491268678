<template>
    <div>
        <slot name="activator" :open="open">
            <v-btn
                icon
                :loading="loadingMsg != null"
                @click="open"
                small
                title="Change Path">
                <v-icon small>mdi-map-marker-path</v-icon>
            </v-btn>
        </slot>

        <Juncture-Builder
            :courier-order="mCourierOrder"
            :openToggle="openToggle"
            @ok="resolve" />
    </div>
</template>

<script>
import JunctureBuilder from '~home/courier-orders/Juncture-Builder.vue';

export default {
    name: 'Juncture-Builder-Btn',
    components: {
        JunctureBuilder
    },
    data: function() {
        return {
            loadingMsg: null,
            mCourierOrder: null,
            openToggle: false
        }
    },
    props: {
        courierOrder: null,
        movement: null
    },
    methods: {
        async open() {
            this.mCourierOrder = null;

            if (this.courierOrder != null) {
                this.mCourierOrder = this.courierOrder;
            }
            else if (this.movement != null) {
                this.loadingMsg = 'Locating Courier Orders';
                var res = await this.$BlitzIt.store.getAll('courier-movements', null, true, null, `/GetCourierOrders/${this.movement.id}`);
                if (this.isArrayOfLength(res, 1)) {
                    this.mCourierOrder = res[0];
                }
                else if (this.isLengthyArray(res)) {
                    this.mCourierOrder = await this.$selectItemFrom({
                        items: res,
                        itemText: 'destinationLocation.companyAccount.companyName',
                        subTextFunction: item => item.destinationLocation,
                        subTextFilter: 'toLocationLine'
                    })
                }
                this.loadingMsg = null;
            }

            if (this.mCourierOrder != null) {
                this.openToggle = !this.openToggle;
            }
        },
        async resolve({ plannedJunctures, isPickupRequest }) {
            var patchObj = {
                ...this.mCourierOrder,
                isPlanRouteRequest: true,
                isCreateMovementsRequest: true,
                plannedJunctures: plannedJunctures,
                isPickupRequest: isPickupRequest
            };

            try {
                this.loadingMsg = 'Saving New Route';

                await this.$BlitzIt.store.patch('client-orders', patchObj);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }

            this.$emit('saved');
        }
    }
}
</script>