<template>
    <BT-Dialog
        :getOnOpen="getObject"
        height="500"
        hideButton
        :item="courierOrder"
        label="Consignment Path"
        :loadingMsg="loadingMsg"
        @ok="returnResult"
        :openToggle="openToggle"
        width="700">
        <template #default="{ item }">

            <v-slide-y-transition group hide-on-leave>
                <BT-List-Endless
                    v-if="isLengthyArray(item.plannedJunctures)"
                    :canSearch="false"
                    :items="item.plannedJunctures"
                    key="1">
                    <template #default="li">
                        <v-list-item-content>
                            <v-list-item-title>
                                <BT-Entity
                                    :itemValue="li.item.destinationLocationID"
                                    navigation="public-locations"
                                    single
                                    textFilter="toFamiliarLocationLine"
                                    useLocalCache />
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ li.item.dueArrivalOn | toDayShortDate }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="mouse-over-show">
                            <v-btn icon small @click="() => removeJuncture(item, li.index)">
                                <v-icon class="error--text" small>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </BT-List-Endless>

                <v-row v-if="!useSuggestion" dense key="2">
                    <v-col cols="6">
                        <BT-Dialog
                            block
                            :disabled="item != null && item.plannedJunctures != null && item.plannedJunctures.some(x => x.isFinalLeg == true)"
                            :getOnOpenAsync="() => getMidJuncture(item)"
                            height="500"
                            icon="mdi-plus"
                            label="Add Mid Juncture"
                            large
                            text="Mid Juncture"
                            width="700"
                            @ok="res => addMidJuncture(item, res)">
                            <template #default="{ item }">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-subheader>Location</v-subheader>
                                        <BT-List-Endless
                                            :canSearch="false"
                                            height="250"
                                            :items="possibleLocations"
                                            itemValue="id"
                                            v-model="item.destinationLocationID">
                                            <template #default="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title v-if="item.companyAccount != null">{{ item.companyAccount.companyName }}</v-list-item-title>
                                                    <v-list-item-title v-else>{{ item.locationName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </BT-List-Endless>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider class="my-2" />
                                        <BT-Date-Picker
                                            v-model="item.dueArrivalOn"
                                            label="Delivery Date" />
                                    </v-col>
                                </v-row>
                            </template>
                        </BT-Dialog>
                    </v-col>
                    <v-col cols="6">
                        <BT-Dialog
                            block
                            :disabled="item != null && item.plannedJunctures != null && item.plannedJunctures.some(x => x.isFinalLeg == true)"
                            :getOnOpenAsync="() => getFinalJuncture(item)"
                            height="500"
                            icon="mdi-plus"
                            label="Date For Last Leg"
                            large
                            text="Last Leg"
                            width="700"
                            @ok="res => addFinalJuncture(item, res)">
                            <template #default="{ item }">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <BT-Date-Picker
                                            v-model="item.dueArrivalOn"
                                            label="Delivery Date" />
                                    </v-col>
                                </v-row>
                            </template>
                        </BT-Dialog>
                    </v-col>
                    <v-col cols="12">
                        <v-divider class="my-2" />
                    </v-col>
                </v-row>

                <v-row no-gutters key="3">
                    <v-col cols="6">
                        <BT-Btn
                            block
                            :buttonClass="useSuggestion == true && item.isPickupRequest == false ? 'success' : null"
                            icon="mdi-auto-fix"
                            label="Give Me A Suggestion"
                            @click="() => getSuggestionsAsync(item)" />
                    </v-col>
                    <v-col cols="6">
                        <BT-Btn
                            block
                            :buttonClass="useSuggestion == false ? 'success' : null"
                            icon="mdi-handball"
                            label="Manual"
                            @click="() => getSuggestionsAsync(item)" />
                    </v-col>
                    <v-col cols="6">
                        <BT-Btn
                            block
                            :buttonClass="item.isPickupRequest == true ? 'success' : null"
                            icon="mdi-clipboard-arrow-up"
                            label="Pickup"
                            @click="() => pickupAsync(item)" />
                    </v-col>
                </v-row>
            </v-slide-y-transition>
        </template>
    </BT-Dialog>
</template>

<script>
import { firstBy } from 'thenby';
export default {
    name: 'Juncture-Builder',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data() {
        return {
            loadingMsg: null,
            possibleLocations: null,
            useSuggestion: false
        }
    },
    props: {
        courierOrder: null,
        openToggle: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.useSuggestion = false;
    },
    methods: {
        getObject(courierOrder) {
            return {
                ...this.copyDeep(courierOrder),
                plannedJunctures: []
            };
        },
        returnResult(res) {
            this.$emit('ok', res); //{ plannedJunctures, isPickupRequest }
            // this.$emit('ok', res.plannedJunctures);
        },
        async pickupAsync(obj) {
            obj.plannedJunctures = [];
            obj.isPickupRequest = true;
        },
        async getSuggestionsAsync(obj) {
            obj.isPickupRequest = false;

            if (this.useSuggestion) {
                obj.plannedJunctures = [];
            }
            else {
                try {
                    this.loadingMsg = 'Getting suggestion...';

                    var res = await this.$BlitzIt.api.getById(
                        'route-suggestions',
                        null,
                        {
                            departureLocationID: this.courierOrder.departureLocationID,
                            destinationLocationID: this.courierOrder.destinationLocationID,
                            dueDate: this.courierOrder.dueDate,
                            preferStartPickup: true,
                            preferEndDropoff: true
                        },
                        null,
                        null);
                        
                    if (res.data.data != null) {
                        res.data.data.routeMovements.sort(firstBy(x => x.sortNumber));
                    }
                    
                    obj.plannedJunctures = res.data.data.routeMovements;
                }
                catch (err) {
                    console.log(err);
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }

            this.useSuggestion = !this.useSuggestion;
        },
        async getFinalJuncture(obj) {
            var depID = obj.departureLocationID;

            if (this.isLengthyArray(obj.plannedJunctures)) {
                depID = obj.plannedJunctures[obj.plannedJunctures.length - 1].destinationLocationID;
            }

            return {
                departureLocationID: depID,
                destinationLocationID: obj.destinationLocationID,
                dueArrivalOn: obj.dueDate,
                sortNumber: obj.plannedJunctures.length + 1,
                isFinalLeg: true
            };
        },
        addFinalJuncture(obj, res) {
            if (res.dueArrivalOn != null) {
                obj.isPickupRequest = false;
                obj.plannedJunctures.push(res);
            }
        },
        async getMidJuncture(obj) {
            if (this.possibleLocations == null) {
                this.loadingMsg = 'Finding Locations';
                var courierLocations = await this.$BlitzIt.store.getAll('couriers', null, false, null, '/getAll/CourierLocations');
                var locations = await this.$BlitzIt.store.getAll('locations');

                this.possibleLocations = [
                    ...courierLocations,
                    ...locations
                ]
                this.loadingMsg = null;
            }

            var depID = obj.departureLocationID;

            if (this.isLengthyArray(obj.plannedJunctures)) {
                depID = obj.plannedJunctures[obj.plannedJunctures.length - 1].destinationLocationID;
            }

            return {
                departureLocationID: depID,
                destinationLocationID: null,
                dueArrivalOn: null,
                sortNumber: obj.plannedJunctures.length + 1
            };
        },
        addMidJuncture(obj, res) {
            if (res.destinationLocationID != null && res.dueArrivalOn != null) {
                obj.isPickupRequest = false;
                obj.plannedJunctures.push(res);
            }
        },
        removeJuncture(obj, index) {
            obj.plannedJunctures.splice(index, 1);
            
            if (this.isLengthyArray(obj.plannedJunctures)) {
                var depID = obj.departureLocationID;
                var i = 1;
                obj.plannedJunctures.forEach(pJun => {
                    pJun.departureLocationID = depID;
                    pJun.sortNumber = i;
                    i++;
                    depID = pJun.destinationLocationID;
                })
            }
        },
    }
}
</script>