var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Dialog',{attrs:{"getOnOpen":_vm.getObject,"height":"500","hideButton":"","item":_vm.courierOrder,"label":"Consignment Path","loadingMsg":_vm.loadingMsg,"openToggle":_vm.openToggle,"width":"700"},on:{"ok":_vm.returnResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-slide-y-transition',{attrs:{"group":"","hide-on-leave":""}},[(_vm.isLengthyArray(item.plannedJunctures))?_c('BT-List-Endless',{key:"1",attrs:{"canSearch":false,"items":item.plannedJunctures},scopedSlots:_vm._u([{key:"default",fn:function(li){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('BT-Entity',{attrs:{"itemValue":li.item.destinationLocationID,"navigation":"public-locations","single":"","textFilter":"toFamiliarLocationLine","useLocalCache":""}})],1),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toDayShortDate")(li.item.dueArrivalOn)))])],1),_c('v-list-item-action',{staticClass:"mouse-over-show"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function () { return _vm.removeJuncture(item, li.index); }}},[_c('v-icon',{staticClass:"error--text",attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}):_vm._e(),(!_vm.useSuggestion)?_c('v-row',{key:"2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('BT-Dialog',{attrs:{"block":"","disabled":item != null && item.plannedJunctures != null && item.plannedJunctures.some(function (x) { return x.isFinalLeg == true; }),"getOnOpenAsync":function () { return _vm.getMidJuncture(item); },"height":"500","icon":"mdi-plus","label":"Add Mid Juncture","large":"","text":"Mid Juncture","width":"700"},on:{"ok":function (res) { return _vm.addMidJuncture(item, res); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("Location")]),_c('BT-List-Endless',{attrs:{"canSearch":false,"height":"250","items":_vm.possibleLocations,"itemValue":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[(item.companyAccount != null)?_c('v-list-item-title',[_vm._v(_vm._s(item.companyAccount.companyName))]):_c('v-list-item-title',[_vm._v(_vm._s(item.locationName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("toLocationLine")(item)))])],1)]}}],null,true),model:{value:(item.destinationLocationID),callback:function ($$v) {_vm.$set(item, "destinationLocationID", $$v)},expression:"item.destinationLocationID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-2"}),_c('BT-Date-Picker',{attrs:{"label":"Delivery Date"},model:{value:(item.dueArrivalOn),callback:function ($$v) {_vm.$set(item, "dueArrivalOn", $$v)},expression:"item.dueArrivalOn"}})],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BT-Dialog',{attrs:{"block":"","disabled":item != null && item.plannedJunctures != null && item.plannedJunctures.some(function (x) { return x.isFinalLeg == true; }),"getOnOpenAsync":function () { return _vm.getFinalJuncture(item); },"height":"500","icon":"mdi-plus","label":"Date For Last Leg","large":"","text":"Last Leg","width":"700"},on:{"ok":function (res) { return _vm.addFinalJuncture(item, res); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('BT-Date-Picker',{attrs:{"label":"Delivery Date"},model:{value:(item.dueArrivalOn),callback:function ($$v) {_vm.$set(item, "dueArrivalOn", $$v)},expression:"item.dueArrivalOn"}})],1)],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"my-2"})],1)],1):_vm._e(),_c('v-row',{key:"3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('BT-Btn',{attrs:{"block":"","buttonClass":_vm.useSuggestion == true && item.isPickupRequest == false ? 'success' : null,"icon":"mdi-auto-fix","label":"Give Me A Suggestion"},on:{"click":function () { return _vm.getSuggestionsAsync(item); }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BT-Btn',{attrs:{"block":"","buttonClass":_vm.useSuggestion == false ? 'success' : null,"icon":"mdi-handball","label":"Manual"},on:{"click":function () { return _vm.getSuggestionsAsync(item); }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BT-Btn',{attrs:{"block":"","buttonClass":item.isPickupRequest == true ? 'success' : null,"icon":"mdi-clipboard-arrow-up","label":"Pickup"},on:{"click":function () { return _vm.pickupAsync(item); }}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }